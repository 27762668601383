/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2022
 */

.item-divider-route{
  --padding-top: 10px;
  --padding-bottom: 10px;
  align-items: start;
}

.btn-google-maps-route{
  position: absolute;
  top: 10px;
  right: 10px;
}
.wrapper-route-map{
  height: 50%;
  position: relative;
}


